import React from "react"
import { Page } from "../../shared/Page/Page"
import { PageHeader } from "../../shared/PageHeader/PageHeader"
import { H3, P, PreSection } from "../../shared/Ui/Typography/Typography"
import { TextButton } from "../../shared/Ui/TextButton/TextButton"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import media from "css-in-js-media"
import Helmet from "react-helmet"
import { Header } from "../../shared/Header/Header"
import { Footer } from "../../shared/Footer/Footer"
import { Link } from "gatsby"

const Container = styled.div`
    background: linear-gradient(
        ${({ theme }) => theme.surfaces.lighter},
        #f8fafc
    );
    box-shadow: ${({ theme }) => theme.surfaces.dark} 0 -50rem 50rem -50px inset;

    > div.content {
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        padding: 50rem 0 100rem;

        .text {
            max-width: 700rem;
            margin-left: 30rem;
            padding: 0 30rem;
            border-left: 1rem solid ${({ theme }) => theme.borders.normal};
        }

        .image {
            border-radius: 10rem;
            box-shadow: rgba(17, 12, 46, 0.15) 0 48rem 100rem 0;
        }

        ${media("<tablet")} {
            .text {
                border-left: none;
                padding: 0;
                margin-left: 0;
            }

            .image {
                display: none;
            }
        }
    }
`
const Industry = ({ location }) => {
    return (
        <Page disableCta disableFooter disableHeader location={location}>
            <Helmet>
                <title>Startup program</title>
            </Helmet>
            <Container className={"overlapPageMargins"}>
                <Header constrained />
                <PageHeader
                    className={"pageConstrained"}
                    breadCrumbs={[
                        { to: "/pricing", title: "Pricing" },
                        { title: "Startups" },
                    ]}
                    h1={"Startup program"}
                    h2={"Programs and benefits for early stage companies"}
                />
                <div className={"content pageConstrained"}>
                    <div className={"text"}>
                        <PreSection>Q4 2021 Program</PreSection>
                        <H3>Overview</H3>
                        <P>
                            We're a startup too, and dedicated to helping other
                            companies in their early days.
                        </P>
                        <P>
                            At the beginning when resources are tight we offer a
                            generous pricing tier for applicants to help
                            bootstrap their new venture.
                        </P>
                        <H3>The program</H3>
                        <P>
                            Successful applicants will immediately gain access
                            to...
                            <ol>
                                <li>Full access to 23shout</li>
                                <li>Two dialling seats included</li>
                                <li>
                                    Ten free outbound calls per day of any
                                    length <sup>1</sup>
                                </li>
                                <li>Unlimited free inbound calls</li>
                                <li>
                                    Unlimited free SMS via{" "}
                                    <Link
                                        to={"/features/team-sms"}
                                        tabIndex={-1}
                                    >
                                        <TextButton color={"primary"}>
                                            Conversations
                                        </TextButton>
                                    </Link>
                                </li>
                            </ol>
                        </P>
                        <P>
                            Membership in the program lasts for six months, at
                            which point your team subscription will
                            automatically roll over onto the{" "}
                            <strong>Pro</strong> plan unless cancelled.
                        </P>
                        <H3>Eligibility</H3>
                        <P>
                            To qualify for our startup program we require
                            prospective applicants to meet the following
                            criteria:
                            <ol>
                                <li>Be a registered business or company</li>
                                <li>
                                    Conducting business of an ethical and decent
                                    nature
                                </li>
                                <li>
                                    Not subject to any fraud notices from
                                    23shout Pty Ltd
                                </li>
                                <li>
                                    Provide documentation showing their business
                                    registration date from an approved regulator
                                    body
                                </li>
                            </ol>
                        </P>
                        <H3>Next steps</H3>
                        <P>
                            We're excited to welcome you to the 23shout family
                            and kick off your journey, let's arrange a meeting
                            sometime to go over the requirements to get started.
                        </P>
                        <P>
                            Contact our team via{" "}
                            <a
                                href={
                                    "mailto:hello@23shout.com?subject=Startup program Q3-2021"
                                }
                            >
                                <TextButton color={"primary"}>
                                    hello@23shout.com
                                </TextButton>
                            </a>{" "}
                            or via our{" "}
                            <Link to={`/support/contact`} tabIndex={-1}>
                                <TextButton color={"primary"}>
                                    contact form
                                </TextButton>
                            </Link>
                            .
                        </P>
                        <P style={{ fontSize: "12rem" }}>
                            <sup>1</sup> Free calls available only to Australia,
                            New Zealand, USA, UK, and Ireland.
                        </P>
                    </div>
                    <StaticImage
                        src={"../../images/pricing/startup.jpg"}
                        alt={"Industry partners"}
                        layout={"fixed"}
                        width={300}
                        height={550}
                        className={"image"}
                        objectPosition={"80% 80%"}
                    />
                </div>
            </Container>
            <Footer constrained />
        </Page>
    )
}

export default Industry
